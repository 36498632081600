import { Route, Routes } from 'react-router-dom';
import { LoginPage } from "./pages/LoginPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { DynamicMainPage } from './pages/DynamicMainPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';

import './App.css';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element = {< LoginPage />}/>
        <Route path="/forgotPassword" element = {< ForgotPasswordPage />}/>
        <Route path="/client" element = {< DynamicMainPage />}/>
        <Route path="/resetPassword" element = {< ResetPasswordPage />}/>
        <Route path="*" element = {< NotFoundPage />}/>
      </Routes>
    </div>
  );
}

export default App;
