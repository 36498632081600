import React, { useState } from "react";
import { useNavigate  } from 'react-router-dom';
import logo from '../Edged_IQ_Logo_Cerulean.svg';
import Footer from "../fragments/Footer";

import { ClientApi } from "../apis/ClientApi";

export const LoginPage = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [displayLoginMessage, setdisplayLoginMessage] = useState(false);
    const [loginMessageResponse, setloginMessageResponse] = useState('');

    let navigate = useNavigate();

    const handleLoginEvent = (event) => {
        event.preventDefault();

        if ( username === "" & password === "") {
            setdisplayLoginMessage(true);
            setloginMessageResponse("Please enter valid data into the Username and Password field...");
        } 
        else {
            ClientApi.authenticateUser({
                email : username,
                password : password 
            }).then((response) => {
                navigate('/client', {state: 
                    {
                        loggedInResponse : response.data
                    }})
            }).catch((event) => {
                setdisplayLoginMessage(true);
                setloginMessageResponse("There was an error logging into your account...");
            })
        }
    };

    const handleForgotPasswordNav = () => {
        navigate('/forgotPassword')
    };

    const handleChangeOfEmailInputField = (event) => {
        setUsername(event.target.value);
        setloginMessageResponse("");
        setdisplayLoginMessage(false);
    }

    const handleChangeOfPasswordInputField = (event) => {
        setPassword(event.target.value);
        setloginMessageResponse("");
        setdisplayLoginMessage(false);
    }

    return (
        <div className="auth-form-container">
            <div className="entry-app-logo-container"> 
                <img src={logo} className="app-entry-logo" alt="logo"/>
            </div>
            <div className ="login-form">
                <div id="errorMessageTextDiv"
                    style={{ 
                        width : "20rem", 
                        fontSize: "13px",
                        paddingTop: "1.5em",
                        display: displayLoginMessage ? 'block' : 'none' }}>
                    { loginMessageResponse }
                </div>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input 
                        id="username" 
                        name="username" 
                        type="email" 
                        placeholder="username" 
                        value={username}
                        onChange={handleChangeOfEmailInputField}/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password </label>
                    <input 
                        id="password" 
                        name="password" 
                        type="password" 
                        placeholder="*********" 
                        value={password}
                        onChange={handleChangeOfPasswordInputField}/>
                </div>   
                <div 
                    id="forgotPasswordText" 
                    className="clickable-text-container"
                    onClick={handleForgotPasswordNav}>
                    Forgot Password
                </div>
                <div>
                <button onClick={handleLoginEvent}>Sign In</button>
                </div>
            </div>
            {<Footer />}
        </div>
    )
}