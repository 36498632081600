import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { ClientApi } from '../apis/ClientApi';

export const CustomPowerBiEmbeddedReport = ({clientId, apiReportConfig}) => {

	const [reportConfig, setReportConfig] = useState({
		type: 'report',
		tokenType: models.TokenType.Embed,
        permissions: models.Permissions.All,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false
        } 
	});

    useEffect(() => {

        ClientApi.embeddedReportCredentails(clientId, apiReportConfig.groupId, apiReportConfig.reportId)
        .then((response) => {
            setReportConfig({
                ...reportConfig,
                embedUrl: response.data.embededdReportUrl,
                accessToken: response.data.embededdReportToken,
                id: response.data.reportId
            });
        })
        .catch((event) => {
    
        });
    
    }, []);

    return (
        <PowerBIEmbed key={reportConfig.accessToken}
            // eventHandlers={{
                
            // }} 
            cssClassName={"powerbi-report-style-class"}
            embedConfig={reportConfig}
        />
    )
}
