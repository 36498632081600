import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { CustomPowerBiEmbeddedReport } from './CustomPowerBiEmbeddedReport';

export const MainPageTabbedComponenet = ({clientId, accessPagesSorted}) => {
    const [currentTab, setCurrentTab] = useState(0);

    const handleOnTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        // tabPanelContent = createTabbedContent(accessPagesSorted[newValue])
    };

    const createTabbedContent = (tabIdx) => {

        if(tabIdx === undefined || accessPagesSorted.length === 0) {
            return (<p>Unsupported Page Content...</p>)
        }

        switch(accessPagesSorted[tabIdx].type.toLowerCase()) {
            case ("EmbeddedWebContent").toLowerCase():
                return (<iframe className='tab-content'
                            src={accessPagesSorted[tabIdx].conponentConfig.url}/>)
            case ("PdfDocument").toLowerCase():
                return (<object className='tab-content'
                            aria-label="PDF-Document"
                            data={accessPagesSorted[tabIdx].conponentConfig.url+"#toolbar=0"}
                            type="application/pdf"/>)
            case ("PowerBiEmbedded").toLowerCase():
                return (<CustomPowerBiEmbeddedReport className='tab-content'
                            key={accessPagesSorted[tabIdx].id}
                            clientId={clientId}
                            apiReportConfig={accessPagesSorted[tabIdx].conponentConfig}/>)
            default:
                return (<p>Unsupported Page Content...</p>)
        }
    }

    return (
        <div className="tabs-and-tab-component">
            <div
                className="tab-bar-container" 
                position="static"> 
                <Tabs
                    // variant="fullWidth"
                    value={currentTab}
                    onChange={handleOnTabChange}
                    aria-label="client-access-tabs"
                    TabIndicatorProps={{style: {backgroundColor: "#1b3c70"}}}
                    >
                    {accessPagesSorted.map((tabContent) => (
                        <Tab 
                            key={tabContent.displayName} 
                            label={tabContent.displayName}
                            style={{width : "auto"}} />
                    ))}
                </Tabs>
            </div>
            <div id='tabContentContainer'
                className='tab-content-window'>
                { createTabbedContent(currentTab) }
            </div>
        </div>
    )
}