import React, { useState } from "react";
import { useNavigate  } from 'react-router-dom';

import logo from '../Edged_IQ_Logo_Cerulean.svg';
import Footer from "../fragments/Footer.jsx";

import { ClientApi } from "../apis/ClientApi";

export const ForgotPasswordPage = (props) => {

    const [email, setEmail] = useState('');
    const [displayForgotPasswordMessage, setdisplayForgotPasswordMessage] = useState(false);
    const [requestStatusMessage, setRequestStatusMessage] = useState('');

    let navigate = useNavigate();

    const handleForgotPasswordSubmit = (event) => {
        event.preventDefault();
        ClientApi.forgotPassword(email).then(() => {
            setRequestStatusMessage("Password Reset Request Successfull!")
            setdisplayForgotPasswordMessage(true);
        }).catch((event) => {
            setRequestStatusMessage("Error with Password Reset Request.")
            setdisplayForgotPasswordMessage(true);
            // forgotPasswordResponseMessageComponent.style.visibility = 'visible';
        })
    }

    const handleReturnToLogin = (event) => {
        event.preventDefault();
        navigate('/');
    }

    const handleChangeOfEmailInputField = (event) => {
        setEmail(event.target.value);
        setRequestStatusMessage("");
        setdisplayForgotPasswordMessage(false);
    }

    return (
        <div className="auth-form-container">
            <form className="forgot-password-form">
                <img src={logo} className="app-entry-logo" alt="logo"/>
                <div style={{width : "20rem", fontSize: "13px"}}>
                    If an account is associated with the bellow email, an email message with password reset steps will be sent. 
                </div>
                <div style={{paddingTop : "10px"}}>
                    <label htmlFor="email">Email</label>
                    <input 
                        id="email" 
                        name="email" 
                        type="email" 
                        placeholder="your account's email" 
                        value={email}
                        onChange={handleChangeOfEmailInputField}>
                    </input>
                </div>
                <div className="two-button-container">
                    <button 
                        id="returnToLogInButton"
                        style={{width: "10rem"}}
                        onClick={handleReturnToLogin}>
                            Return to Login
                    </button>
                    <button 
                        id="forgotPasswordButton"
                        style={{width: "10rem"}}
                        onClick={handleForgotPasswordSubmit}>
                            Reset Password
                    </button>
                </div>               
                <div id="forgotPasswordResponseUpdate"
                    style={{ 
                        width : "20rem", 
                        fontSize: "13px",
                        paddingTop: ".5em",
                        display: displayForgotPasswordMessage ? 'block' : 'none' }}>
                    {requestStatusMessage}
                </div> 
                </form>
            {<Footer/>}
        </div>
    )
}