import React from "react";

import logo from '../Edged_IQ_Logo_Cerulean.svg';
import Footer from "../fragments/Footer.jsx";

export const NotFoundPage = (props) => {

    return (
        <div className="auth-form-container">
            <img src={logo} className="app-entry-logo" alt="logo"/>
            <h1>404 Page Not Found</h1>
            <h3 style={{width : "20rem", fontSize: "13px"}}>
                The page you're looking for was not found...
            </h3>
            {<Footer/>}
        </div>
    )
}