import React from 'react';

class Footer extends React.Component {
    render() {
        const year = new Date().getFullYear()

        return (
            <div className="footer-text">
                <p>&#169; {year} EdgedIQ. All Right Reserved.</p>
            </div>
        )
    }
}

export default Footer;