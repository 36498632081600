import axios from "axios"

export const clientApiAxiosConfig = axios.create({
    withCredentials: false,
    baseURL: "https://edgediq-apimngt-dev-1.azure-api.net/api-qa",
  })
  
  // defining a custom error handler for all APIs
  const errorHandler = (error) => {
    const statusCode = error.response?.status
  
    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
      console.error(error)
    }
  
    return Promise.reject(error)
  }
  
  // registering the custom error handler to the
  // "api" axios instance
  clientApiAxiosConfig.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
  })