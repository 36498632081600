import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate  } from 'react-router-dom';

import logo from '../Edged_IQ_Logo_Cerulean.svg';
import Footer from "../fragments/Footer.jsx";
import { ClientApi } from "../apis/ClientApi";

export const ResetPasswordPage = (props) => {

    let navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [confirmedPassword, setconfirmedPassword] = useState('');
    const [passwordResetEventMessage, setPasswordResetEventMessage] = useState('');
    const [passwordResetEventMessageEnabled, setPasswordResetEventMessageEnabled] = useState(false);


    const [searchParams, setSearchParams] = useSearchParams();
    const urlToken = searchParams.get("token");
    const policyId = searchParams.get("policyId");

    const handleChangeOfPasswordInputField = (event) => {
        setPassword(event.target.value);
        setPasswordResetEventMessage("");
        setPasswordResetEventMessageEnabled(false);
    }

    const handleChangeOfConfirmedPasswordInputField = (event) => {
        setconfirmedPassword(event.target.value);
        setPasswordResetEventMessage("");
        setPasswordResetEventMessageEnabled(false);
    }

    const handlePasswordResetSubmissionEvent = (event) => {
        event.preventDefault();

        if ( confirmedPassword === "" & password === "" || confirmedPassword != password) {
            setPasswordResetEventMessageEnabled(true);
            setPasswordResetEventMessage("Please review your submissions for policy and confirmation.");
        } 
        else {
            ClientApi.passwordReset({
                password : password,
                confirmPassword : confirmedPassword,
                token: urlToken,
                userPolicyId: policyId
            }).then((response) => {
                setPasswordResetEventMessageEnabled(true);
                setPasswordResetEventMessage("Your password was set succesfully! Please stand by while your taken to the login page...");

                navigate('/')

            }).catch((event) => {
                setPasswordResetEventMessageEnabled(true);
                setPasswordResetEventMessage("There was an error resetting your password... please review your submissions for policy and confirmation.");
            })
        }
    };

    return (
        <div className="auth-password-reset-form">
            <img src={logo} className="app-entry-logo" alt="logo"/>
            <h3 style={{width : "20rem", fontSize: "13px"}}>
                <p>Plese reset you password with the following policy</p>
                <p>- At least [12] characters</p>
                <p>- At least 1 special character</p>
                <p>- At least 1 uppercase character</p>
                <p>- At least 1 number</p>
            </h3>
            <form className ="password-reset-form">
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input 
                        id="password" 
                        name="password" 
                        placeholder="" 
                        value={password}
                        onChange={handleChangeOfPasswordInputField}/>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmedPassword">Re-Enter Password</label>
                    <input 
                        id="confirmedPassword" 
                        name="confirmedPassword" 
                        placeholder="" 
                        value={confirmedPassword}
                        onChange={handleChangeOfConfirmedPasswordInputField}/>
                </div>
            </form>
            <div className="two-button-container">
                    {/* <button {}
                        id="returnToLogInButton"
                        style={{width: "10rem"}}
                        onClick={handlePasswordResetSubmissionEvent}>
                            Return to Login
                    </button> */}
                    <button 
                        id="resetPasswordButton"
                        style={{width: "10rem"}}
                        onClick={handlePasswordResetSubmissionEvent}>
                            Reset Password
                    </button>
                </div>    
            <div id="errorMessageTextDiv"
                style={{ 
                    width : "20rem", 
                    fontSize: "13px",
                    paddingTop: "1.5em",
                    display: passwordResetEventMessageEnabled ? 'block' : 'none' }}>
                { passwordResetEventMessage }
            </div>
            {<Footer/>}
        </div>
    )
}