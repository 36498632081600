import React, { useEffect, useState } from 'react';
import logo from '../Edged_IQ_Logo_Cerulean.svg';
import { useLocation, useNavigate  } from 'react-router-dom';
import { ClientApi } from "../apis/ClientApi";
import { MainPageTabbedComponenet } from '../fragments/MainPageTabbedComponent';
import Footer from "../fragments/Footer";

export const DynamicMainPage = (props) => {
    
    const { state } = useLocation();
    const { loggedInResponse } = state;
    const [ userTabs , setUserTabs] = useState([]);
    const [ clientId , setClientId] = useState();

    let navigate = useNavigate();

    const handleLogoutSubmit = (event) => {
        event.preventDefault();
        navigate('/')
    };

    useEffect(() => {
        
        setClientId(loggedInResponse.userClients[0].id);

        if (loggedInResponse.userClients.filter(client => client.id === "de1c9404-9efa-4df4-9301-ea7030b3734e").length === 1) {
            ClientApi.userAccess(clientId, loggedInResponse.accessToken)
            .then((response) => {
                let sortedTabs = response.data.sort((a, b) => a.OrderHierarchy - b.OrderHierarchy)
                setUserTabs(sortedTabs);
            })
            .catch((event) => {
        
            });
        }
    }, []);

    return (
        <div className = "dynamic-main-page-container">
            <div className = "dynamic-main-page-header">
                <div className = "main-page-header-app-logo-container">
                    <img className = "main-page-header-app-logo" src = {logo} alt = "logo"/>
                </div>
                <div className ="main-page-header-user-info-text">
                    Welcome {loggedInResponse.firstName} ({loggedInResponse.email})
                </div>
                <div className = "main-page-header-logout-button-container">
                    <button className="log-out-button"
                        id = "logOutButton"
                        onClick={handleLogoutSubmit}>
                        Log Out
                    </button>
                </div>
            </div>
            <MainPageTabbedComponenet 
                accessPagesSorted={userTabs}
                clientId={clientId}/> 
            {<Footer/>}
        </div>
    )
}

// export default DynamicMainPage