import { clientApiAxiosConfig } from "./config/clientApiAxiosConfig"
import { defineCancelApiObject } from "../apis/config/axiosUtils"

export const ClientApi = {
  authenticateUser: async function (authRequest, cancel = false) {
    const response = await clientApiAxiosConfig.request({
      url: `/user/authenticate`,
      method: "POST",
      data: authRequest,
      // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the product returned by the API
    return response
  },
  passwordReset: async function (model, cancel = false) {
    const response = await clientApiAxiosConfig.request({
      url: `/user/resetPassword`,
      method: "POST",
      data: model
      // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the product returned by the API
    return response
  },

  forgotPassword: async function (email, cancel = false) {

    const params = {
      'email' : email
    };
    
    const response = await clientApiAxiosConfig.request({
      url: '/user/forgotPassword',
      method: "GET",
      params: params
      // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the product returned by the API
    return response
  },

  validateTokenAndGetPolicy: async function (token, policyId, cancel = false) {

    const params = {
      'token' : token,
      'policyId' : policyId
    };
    
    const response = await clientApiAxiosConfig.request({
      url: '/user/validateUserTokenAndPolicy',
      method: "GET",
      params: params
      // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the product returned by the API
    return response
  },

  userAccess: async function (clientId, securityToken, cancel = false) {

    const headers = {
      'securityToken' : securityToken
    };
    
    const response = await clientApiAxiosConfig.request({
      url: "/client/"+clientId+"/pages/user",
      method: "GET",
      headers: headers
      // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the product returned by the API
    return response
  },

  embeddedReportCredentails: async function (clientId, groupId, reportId, cancel = false) {
    const response = await clientApiAxiosConfig.request({
      url: "/client/"+clientId+"/pages/powerBiEmbeddedToken",
      method: "GET",
      params : {
        groupId : groupId,
        reportId : reportId
      }
      // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the product returned by the API
    return response
  }
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ClientApi)